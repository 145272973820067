<!--  -->
<template>
  <div class="top">
    <div class="head_box">
      <!-- 网络域名设置 -->
      <h4 class="head_title">{{$t('vue_label_systemSettings_url')}}</h4>
      <!-- 链接域 -->
      <el-button type="primary" class="head_bt" size="samll" @click="routeTo"
        >{{$t('c47')}}</el-button
      >
    </div>
    <div class="content_list">
      <div class="dnsName">Landing pages</div>
      <div class="dnsMain">
        <div>info.cloudcc.com</div>
        <div class="optionTips">
          <div class="round_img" :style="`background:${this.colorFlg}`"></div>
          <span class="ml-10">{{ stateTitle }}</span>
        </div>
      </div>
      <el-dropdown class="dropdown" @command="handleCommand">
        <!-- 编辑 -->
        <el-button size="mini"> {{ $t("label.department.user.edit") }} </el-button>
        <el-dropdown-menu slot="dropdown">
          <!-- 断开 -->
          <el-dropdown-item command="off">{{$t('c48')}}</el-dropdown-item>
          <!-- 替换 -->
          <el-dropdown-item command="replace">{{ $t("label.document.replaces") }}</el-dropdown-item>
          <!-- 更新域安全设置 -->
          <el-dropdown-item command="update">{{$t('c49')}}</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      colorFlg: '#008844',
      stateTitle: 'Active'
    };
  },
  methods: {
    routeTo() {
      this.$router.push(
        { path: '/marketSetHost' }
      )
    },
    handleCommand(command) {
      switch (command) {
        case 'off':
          this.off()
          break;
        case 'replace':
          this.replace()
          break;
        case 'update':
          this.update()
          break;
      }
    },
    
    off() {
      this.colorFlg = '#e5851a',
        this.stateTitle = "Disconnect"
    },
    
    replace() {},
    
    update() {}
  },
}
</script>
<style lang='scss' scoped>
.top {
  padding: 10px;
  .head_box {
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .head_title {
      font-family: SourceHanSansCN-Normal;
      font-size: 12px;
      color: #080707;
    }
    .head_bt {
      width: 100px;
    }
  }
  .content_list {
    width: 100%;
    height: 90px;
    background: #ffffff;
    border: 1px solid #dedcda;
    border-radius: 2px;
    border-radius: 2px;
    display: flex;

    align-items: center;
    .dnsMain {
      width: 80%;
      .optionTips {
        display: flex;
        align-items: center;
        height: 30px;
        .round_img {
          border: 1px solid #dddbda;
          height: 10px;
          width: 10px;
          border-radius: 50%;
        }
        span {
          font-family: PingFangSC-Semibold;
          font-weight: bold;
          font-size: 12px;
          color: #080707;
        }
      }
    }
    .dnsName {
      padding-left: 30px;
      width: 30%;
    }
    .dropdown {
      width: 10%;
    }
  }
}
</style>